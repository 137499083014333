import { __spreadArray } from "tslib";
import { useLayoutEffect, useRef } from "react";
var initialElementRect = {
    width: 0,
    height: 0,
    y: 0,
    x: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};
export var useResize = function (ref, onResize, dependencies) {
    var resizeObserverRef = useRef();
    var rectRef = useRef(initialElementRect);
    var didUnmount = useRef(false);
    useLayoutEffect(function () {
        var _a;
        didUnmount.current = false;
        if (ref) {
            resizeObserverRef.current = new ResizeObserver(function (entries) {
                var newSize = ref.getBoundingClientRect();
                if (!Array.isArray(entries) ||
                    !entries.length ||
                    didUnmount.current ||
                    (newSize.width === rectRef.current.width &&
                        newSize.height === rectRef.current.height))
                    return;
                onResize(newSize, ref);
                rectRef.current = newSize;
            });
            (_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.observe(ref);
        }
        return function () {
            var _a;
            didUnmount.current = true;
            if (ref) {
                (_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(ref);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([onResize, ref], dependencies, true));
};
